import React, { ReactElement } from 'react';
import './Main.scss';

// import Prizes from '../../assets/images/banana_prizes.png';
import Banner from '../../assets/images/banner.png';

import { HashLink } from 'react-router-hash-link';
import Rules from '../../components/Rules/Rules';
import Winners from '../../components/winners/Winners';
import Register from '../../components/register/Register';
import { useTranslation } from 'react-i18next';
import Logo from '../../assets/images/logo.png';

const Main: React.FC = (): ReactElement => {
  const { t } = useTranslation();

  return (
    <div className="Main">
      <section className="banner">
        <div className="banner-margins">
          <div className="columns">
            <div className="banner-header column is-full-desktop">
              <p>
                <span className="block-span">
                  Osta mis tahes Tutti Frutti komme, registreeri ostutšekk
                  kampaanialehel ja võida auhindu!
                </span>{' '}
                <span className="block-span secondary">
                  Loosis on 2 glämpingutelki ja 4 JBL Charge 5 kaasaskantavat
                  kõlarit.
                </span>
              </p>
              <div className="campaign-period">
                <span>{t('campaign period')}</span>
              </div>
              <HashLink to="#register" className="button">
                {t('register')}
              </HashLink>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="header">
        <Header />
      </section> */}

      <section className="register-form">
        <div className="container">
          <div className="columns">
            <div className="column">
              <Register />
            </div>
          </div>
        </div>
      </section>
      <section className="rules-section" id="rules">
        <div className="container">
          <Rules />
        </div>
      </section>
      <section className="winners-section" id="winners">
        <div className="container">
          <Winners />
        </div>
      </section>
    </div>
  );
};

export default Main;
