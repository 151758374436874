import React, { ReactElement, useEffect, useState } from 'react';
import './Winners.scss';
import _ from 'lodash';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import WinnersTitle from '../../assets/images/voitjad.png';

interface PrizeList {
  jbl: Pick<any, string | number | symbol>[];
  telk: Pick<any, string | number | symbol>[];
}

const Winners: React.FC = (): ReactElement => {
  const { t } = useTranslation();

  const [winners, setWinners] = useState<PrizeList>({
    jbl: [],
    telk: [],
  });

  const getWinners = async () => {
    const res = await axios.get('/api/dataservice/winners/');

    // console.log(res);
    const prizes = _.mapValues(_.groupBy(res.data, 'prize'), (plist) =>
      plist.map((prize) => _.omit(prize, 'prize'))
    );

    setWinners({
      jbl: prizes.jbl === undefined ? [] : prizes.jbl,
      telk: prizes.telk === undefined ? [] : prizes.telk,
    });
  };

  useEffect(() => {
    getWinners();
  }, []);

  return (
    <div className="winners">
      {/* <h2>{t('winners')}</h2> */}
      <div className="title-wrapper">
        <img src={WinnersTitle} alt="" className="title-image" />
      </div>
      <div className="winners-wrapper columns">
        <div className="column">
          <ul className={winners.telk.length === 0 ? 'hidden' : ''}>
            <li className="prize-name">Glämpingutelk</li>

            {/*winners.telk.map(function (item: any, index: any) {
              return (
                <li key={index}>{item.first_name + ' ' + item.last_name}</li>
              );
            })*/}
            {/* <li>name name</li> */}
          </ul>
          <ul className={winners.jbl.length === 0 ? 'hidden' : ''}>
            <li className="prize-name">JBL Charge 5</li>
            {/*winners.jbl.map(function (item: any, index: any) {
              return (
                <li key={index}>{item.first_name + ' ' + item.last_name}</li>
              );
            })*/}
            {/* <li>name name</li> */}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Winners;
