import React, { ReactElement } from 'react';
import './Rules.scss';
import RulesTitle from '../../assets/images/kampaaniareeglid.png';

const Et: React.FC = (): ReactElement => {
  return (
    <div className="Et rules-wrapper">
      <div className="title-wrapper">
        <img src={RulesTitle} alt="" className="title-image" />
      </div>
      <ol>
        <li>
          Kampaania “ Tutti Frutti maitseb nagu puhkus!” on alates 01.06.2024
          kuni 30.06.2024 toimuv tarbijamäng (edaspidi kampaania), mida
          korraldab Fazer Eesti OÜ, (asukoht: Pärnu maantee 158/1, Tallinn;
          edaspidi korraldaja).
        </li>
        <li>
          Kampaania auhinnafondi kuulub 2x glämpingutelki Sibley 450 Pro,
          väärtusega 811,30€ ja 4xJBL Charge5 bluetooth kõlarit, väärtusega
          149,90€, mis loositakse välja 01.07.2024. Auhinnafondi koguväärtus on
          2222,2€. Auhinnad paneb välja rippvoodi.ee (glämpingu telk) ja
          onoff.ee (JBL bluetooth kõlar).
        </li>
        <li>
          Kampaania läbiviimise kord on kehtestatud korraldaja poolt
          käesolevates reeglites. Kõik korraldaja otsused kampaania läbiviimisel
          on lõplikud ning kõigile kampaanias osalejatele kohustuslikud.
        </li>
        <li>
          Kampaaniatooted:
          <table>
            <tbody>
              <tr>
                <td>6416453038288</td>
                <td>Tutti Frutti Original kommikott 180g</td>
              </tr>
              <tr>
                <td>6416453036383</td>
                <td>Tutti Frutti Passion kommikott 180g</td>
              </tr>
              <tr>
                <td>6416453036413</td>
                <td>Tutti Frutti Sour kommikott 180g</td>
              </tr>
              <tr>
                <td>6416453036321</td>
                <td>Tutti Frutti Choco kommikott 170g</td>
              </tr>
              <tr>
                <td>6416453066441</td>
                <td>Tutti Frutti Paradise kommikott 120g</td>
              </tr>
              <tr>
                <td>6416453049284</td>
                <td>Tutti Frutti Summer Rings Sour 160g</td>
              </tr>
              <tr>
                <td>6416453038295</td>
                <td>Tutti Frutti Original kommikott 350g</td>
              </tr>
              <tr>
                <td>6411401037870</td>
                <td>Tutti Frutti Yoghurt Splash kommikott 350g</td>
              </tr>
              <tr>
                <td>6416453049161</td>
                <td>Tutti Frutti Flower Power kommikott 325g</td>
              </tr>
            </tbody>
          </table>
        </li>
        <li>
          <span className="block-span">
            Kampaanias osalemiseks tuleb osta vähemalt üks Tutti-Frutti toode ja
            registreerida ostutšeki number interneti kaudu kampaanialehel
            www.fazer.ee. Kampaanias osalemine on tasuta. Auhinna kättesaamiseks
            tuleb kampaania korraldajale esitada registreeritud ostutšekk.
          </span>
          <span className="block-span">
            Loosimises osalemiseks võib ühe ostutšeki numbri esitada ainult ühe
            korra. Sama ostutšeki mitmekordsel registreerimisel osaleb ainult
            esimesena saabunud registreering.
          </span>
          <span className="block-span">Isikuandmete töötlemise kord</span>
          <span className="block-span">
            Fazer Eesti OÜ töötleb kampaania käigus osaleja poolt esitatud
            isikuandmeid vastavalt Eesti Vabariigis kehtivatele seadustele
            kampaania läbiviimise eesmärgil.
          </span>
          <span className="block-span">
            Kampaanias osaleja annab Fazer Eesti OÜ-le nõusoleku töödelda
            esitatud andmeid järgnevatel eesmärkidel:
          </span>
          <span className="block-span">
            <span className="block-span no-padding">
              kampaania tulemuste kohta kokkuvõtete tegemiseks;
            </span>
            <span className="block-span no-padding">
              kampaaniaga seotud koostööpartneritele info edastamiseks;
            </span>{' '}
            <span className="block-span no-padding">
              võitjate välja loosimiseks ja nende teavitamiseks;
            </span>{' '}
            <span className="block-span no-padding">
              võitjate nimede avaldamiseks kampaania veebilehel.
            </span>
          </span>
          <span className="block-span">
            Kampaanias osalejatel on õigus saada lisainfot Fazer Eesti OÜ poolt
            isikuandmete töötlemise kohta. Selleks tuleb saata e-kiri
            aadressile: <a href="mailto:info@fazer.ee">info@fazer.ee</a>{' '}
          </span>
          <span className="block-span">
            Isikuandmete vastutav töötleja on Fazer Eesti OÜ, registrikood
            10057691, Pärnu maantee 158/1, Tallinn 11317; tel 6502421;{' '}
            <a href="mailto:info@fazer.ee">info@fazer.ee</a>
          </span>
          <span className="block-span">
            Kogutud andmeid säilitatakse kolm kuud alates kampaania
            lõpukuupäevast ehk kuni 30.09.2024.
          </span>
          <span className="block-span">
            Võitjate täielik nimekiri on veebilehel üleval kuni 31.07.2024.
          </span>
        </li>
        <li>
          <span className="block-span">
            01.07.2024 kell 12.00 loositakse kõikide kampaanias osalejate vahel
            välja 2 x glämpingutelki Sibley 450 Pro ja 4 x JBL Charge5 bluetooth
            kõlarit.
          </span>
          <span className="block-span">
            Auhinna loosimises osalevad kõik veebis registreerunud isikud
            kampaania reeglites esitatud perioodil (alates keskkonna
            avanemisest). Võitjat informeeritakse telefoni või meili teel.
            Loosimise juures viibib vähemalt üks Fazer Eesti OÜ esindaja.
          </span>
        </li>
        <li>
          Auhinna võitja nimi avaldatakse internetis aadressil{' '}
          <a href="http://www.fazer.ee">www.fazer.ee</a> hiljemalt loosimisele
          järgneval kolmandal tööpäeval. Võitjaga võetakse korraldaja esindaja
          poolt ühendust andmete ja auhinna kättetoimetamisviisi täpsustamiseks.
        </li>
        <li>
          Juhul, kui auhinna võitja ei vasta korraldaja poolt kehtestatud
          kampaania tingimustele või kui ta ei ole alles hoidnud toote
          ostutšekki, millel oleva numbri ta edastas kampaania korraldajale
          veebi vahendusel, on õigus auhind uuesti välja loosida järgmisele
          isikule. Samuti juhul, kui auhinna võitja ei ole hiljemalt nädala
          jooksul alates, tema kui auhinna võitja nime avalikustamist internetis
          aadressil <a href="http://www.fazer.ee">www.fazer.ee</a> olnud veebis
          esitatud kontaktandmetel kättesaadav andmete täpsustamiseks, on
          korraldajal õigus jätta talle auhind üle andmata ning korraldada uus
          loosimine.
        </li>
        <li>
          Korraldajalt ükskõik missuguse kampaania auhinna saaja annab loa
          korraldajale kasutada võitja nime meedias, reklaamides,
          PR-kampaaniates jms üritustel ilma võitjale selle eest täiendavat tasu
          maksmata ja vastavat kokkulepet sõlmimata.{' '}
        </li>
        <li>
          Kampaania käigus kampaanias osaleja poolt võidetud auhinda ei asendata
          võitja nõudmisel teist liiki auhinnaga ega hüvitata võitjale auhinna
          maksumust rahas, v.a juhul, kui auhind asendatakse teise samaväärse
          auhinnaga korraldaja otsusel.{' '}
        </li>
        <li>
          Auhinna võitjale üleandmisega seotud riiklikud maksud tasub korraldaja
          Eesti Vabariigi seadustes sätestatud korras ja tähtaegadel.{' '}
        </li>
        <li>
          Kampaanias osaleja poolt kampaania tingimuste eiramise või
          korraldajale enda kohta valede või ebatäpsete andmete esitamise
          korral, samuti muude ebaõnnestumiste korral (korraldajal ei õnnestu
          auhinna võitjaga kontakti saada), mis ei ole tingitud korraldaja
          süüst, ei vastuta korraldaja kampaanias osaleja auhindade loosimisest
          või auhinna kätteandmisest kõrvalejäämise eest.
        </li>
        <li>
          Vääramatu jõu (force majeure) asjaolude ilmnemisel on korraldajal
          ühepoolne õigus katkestada kampaania ja jätta auhinnad välja loosimata
          ja/või auhinnad võitjatele üle andmata, teatades sellest koheselt
          kampaanias osalejatele meedia vahendusel.{' '}
        </li>
        <li>
          Kõik kampaaniast tulenevad vaidlused lahendatakse vastavalt Eesti
          Vabariigi seadusandlusele.
        </li>
        <li>
          Kõik pretensioonid seoses kampaania korraldamise ja/või läbiviimisega
          tuleb saata kirjalikult kampaania korraldaja asukohta: Pärnu maantee
          158/1, 11317 Tallinn märksõnaga “KAMPAANIA" hiljemalt 01.07.2024.
          Kaebused, mis esitatakse peale nimetatud kuupäeva, ei võeta menetluse.
          Laekunud pretensioonidele vastatakse 3 tööpäeva jooksul. Kampaania
          lisainfo telefonil 650 2421;{' '}
          <a href="mailto:info@fazer.ee">info@fazer.ee</a>{' '}
        </li>
      </ol>
    </div>
  );
};

export default Et;
